<template>
  <div class="RequeteList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- MODIFY REQUETE -->
    <b-modal ref="modifyRequeteModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Modifier votre requête </strong>
      </template>
      <div>
        <b-form v-on:submit.prevent="modifyRequete()">
          <b-form-group id="modify-requete-name-group" label="Nom de la requête" label-for="modify-requete-name-input" class="mandatory-input">
            <b-form-input
              v-model="modifyRequeteName" type="text"
              maxlength="250"
              placeholder="Nom"
              id="modify-requete-username-input"
              @input="$v.modifyRequeteName.$touch()"
              :state="$v.modifyRequeteName.$dirty ? !$v.modifyRequeteName.$error : null">
            </b-form-input>
            <div class="form-error" v-if="$v.modifyRequeteName.$error">Le nom de la requête doit faire entre 2 et 250 caractères</div>
          </b-form-group>

          <b-form-group id="modify-requete-inclusion-group" label="Requête" label-for="modify-requete-inclusion-input" class="mandatory-input">
            <b-form-textarea
              v-model="modifyRequeteInclusion" type="text"
              placeholder="Xpath d'inclusion"
              id="modify-requete-username-input"
              rows="6"
              @input="$v.modifyRequeteInclusion.$touch()"
              :state="$v.modifyRequeteInclusion.$dirty ? !$v.modifyRequeteInclusion.$error : null">
            </b-form-textarea>
            <div class="form-error" v-if="$v.modifyRequeteInclusion.$error">Veuillez renseigner une requête de plus de 2 caractères</div>
          </b-form-group>

          <b-form-group id="modify-requete-second_part_inclusion-group" label="Seconde requête" label-for="create-requete-second_part_inclusion-input" class="mandatory-input">
            <b-form-textarea
              v-model="modifyRequeteSecondPartInclusion" type="text"
              placeholder="Xpath d'inclusion"
              id="modify-second_part_inclusion-username-input"
              rows="10"
              @input="$v.modifyRequeteSecondPartInclusion.$touch()"
              :state="$v.modifyRequeteSecondPartInclusion.$dirty ? !$v.modifyRequeteSecondPartInclusion.$error : null">
            </b-form-textarea>
            <div class="form-error" v-if="$v.modifyRequeteSecondPartInclusion.$error">Veuillez renseigner une requête d'au moins 2 caractères</div>
          </b-form-group>


          <b-form-group id="modify-requete-exclusion-group" label="Requête d'exclusion" label-for="modify-requete-exclusion-input">
            <b-form-textarea
              v-model="modifyRequeteExclusion" type="text"
              placeholder="Xpath d'exclusion"
              rows="6"
              id="modify-requete-username-input">
            </b-form-textarea>
          </b-form-group>

          <b-button
            pill block type="submit" variant="outline-dark" class="mt-2"
            :disabled="isInvalidModifyRequete">
               Modifier votre requête
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <b-modal ref="RequeteUpdatedModal" id="RequeteUpdatedModal" size="lg" hide-footer title="Requête modifiée">
      <div class="text-center">
        <p><strong>Votre requête a bien été mise à jour</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('RequeteUpdatedModal')">Fermer</b-button>
      </div>
    </b-modal>


    <!-- DELETE REQUETE -->
    <b-modal ref="deleteRequeteModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Supprimer cette requête </strong>
      </template>
      <div>
        <p>
          Attention, si vous supprimez cette requête, il ne sera plus possible de l'utiliser et les pages et dossiers liés ne le seront plus.
        </p>
        <b-button
          pill block variant="outline-danger" class="mt-2"
          @click='deleteRequete'
          :disabled="deleteRequeteInProcess">
            Je confirme la suppression de la requête
        </b-button>
      </div>
    </b-modal>

    <b-modal ref="RequeteDeletedModal" id="RequeteDeletedModal" size="lg" hide-footer title="Requête supprimée">
      <div class="text-center">
        <p><strong>Votre requête a bien été supprimée</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('RequeteDeletedModal')">Fermer</b-button>
      </div>
    </b-modal>


    <!-- TEST REQUETE -->
    <b-modal ref="testRequeteModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Tester votre requête </strong>
      </template>
      <div>
        <b-form v-on:submit.prevent="testRequete()">

          <b-form-group id="test-requete-text-group" label="Texte à tester" label-for="test-requete-text-input" class="mandatory-input">
            <b-form-textarea
              v-model="testRequeteText" type="text"
              placeholder="Coller ici votre texte à tester"
              id="test-requete-text-input"
              rows="6"
              @input="$v.testRequeteText.$touch()"
              :state="$v.testRequeteText.$dirty ? !$v.testRequeteText.$error : null">
            </b-form-textarea>
            <div class="form-error" v-if="$v.testRequeteText.$error">Veuillez renseigner un texte de plus de 2 caractères et de moins de 1 000 000</div>
          </b-form-group>

          <b-button
            pill block type="submit" variant="outline-dark" class="mt-2"
            :disabled="$v.testRequeteText.$invalid ">
               Tester votre requête
          </b-button>
        </b-form>

        <div v-if="testRequeteHasBeenDone">
          <hr>
          <strong> Résultats </strong>
          <p>
            <span>Nombre de keywords détectés : {{testRequeteNbKeywordsDetected}}</span><br>
            <span v-if="testRequeteNbKeywordsDetected > 0" class="text-success"> Votre texte satisfait la requête</span>
            <span v-else class="text-danger"> Votre texte ne satisfait pas la requête</span>
          </p>
          <p>
            <span> Highlights </span>
            <small v-html="testRequeteHighlights"></small>
          </p>
        </div>

      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Paramétrage des requêtes</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Liste des requêtes de Decidcrawl</strong>
              </div>
              <div class="align-self-center col-12 col-sm-6 text-right">
                <b-button variant="outline-success" pill @click="$router.push('/requetes/creation')">
                  <v-icon name="plus"/> Ajouter une requête
                </b-button>
              </div>
            </div>
            <b-table
              responsive class="align-middle mt-2"
              headVariant="light"
              :fields="requetesRenderFields"
              :items="requetes"
              >
              <template v-slot:cell(actions)="data">
                <b-button
                  variant="outline-success" size="sm"
                  v-b-popover.hover.top="'Tester votre requête'"
                  @click="showTestRequeteModal(data.item.id)">
                  <v-icon name="question"/>
                </b-button>
                <b-button
                  variant="outline-primary" class="ml-4" size="sm"
                  @click='showModifyRequeteModal(data.item)'>
                  <v-icon name="edit"/>
                </b-button>
                <b-button
                  variant="outline-danger" class="ml-4" size="sm"
                  @click='showDeleteRequeteModal(data.item)'>
                  <v-icon name="trash-alt"/>
                </b-button>
              </template>
            </b-table>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { APIRequete } from '@/api/APIRequete'
import formTitleComponent from '@/components/formTitleComponent'

const apiRequete = new APIRequete()

export default {
  name: 'RequeteList',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      requetes: [],
      requetesRenderFields: [
        { key: "name", label: "Nom de la requête", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isRequetesLoading: true,

      // ----- MODIFY USER ---------
      modifyRequeteInProcess: false,
      modifyRequeteId: '',
      modifyRequeteName: '',
      modifyRequeteInclusion: '',
      modifyRequeteSecondPartInclusion: '',
      modifyRequeteExclusion: '',

      // ----- DELETE REQUETE -----
      deleteRequeteInProcess: false,
      deleteRequeteId: '',

      // ----- TEST REQUETE ------
      testRequeteInProcess: false,
      testRequeteText: '',
      testRequeteHasBeenDone: false,
      testRequeteNbKeywordsDetected: 0,
      testRequeteHighlights: ''
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isRequetesLoading || this.modifyRequeteInProcess || this.deleteRequeteInProcess || this.testRequeteInProcess) {
        return true
      }
      return false
    },
    isInvalidModifyRequete () {
      if (this.$v.modifyRequeteName.$invalid || this.$v.modifyRequeteInclusion.$invalid || this.$v.modifyRequeteSecondPartInclusion.$invalid) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyRequeteName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    modifyRequeteInclusion: {
      minLength: minLength(2),
      required
    },
    modifyRequeteSecondPartInclusion: {
      minLength: minLength(2),
    },
    testRequeteText: {
      minLength: minLength(2),
      maxLength: maxLength(1000000)
    }

  },
  created: function() {
    this.getAllRequetes()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllRequetes () {
      this.isRequetesLoading = true
      apiRequete.getAllRequetes(this.token)
      .then((result) => {
        this.requetes = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteList getAllRequetes API Error : ' + String(error))
      })
      .finally(() => {
        this.isRequetesLoading = false
      })
    },

    // ----------- MODIFY REQUETE -----
    showModifyRequeteModal (requete) {
      this.modifyRequeteId = requete.id
      this.modifyRequeteName = requete.name
      this.modifyRequeteInclusion = requete.inclusion
      this.modifyRequeteSecondPartInclusion = requete.second_part_inclusion
      this.modifyRequeteExclusion = requete.exclusion

      this.$refs.modifyRequeteModal.show()
    },

    modifyRequete () {
      this.modifyRequeteInProcess = true
      apiRequete.modifyRequete(
        this.token, this.modifyRequeteId, this.modifyRequeteName,
        this.modifyRequeteInclusion, this.modifyRequeteSecondPartInclusion, this.modifyRequeteExclusion)
      .then(() => {
        this.$refs.modifyRequeteModal.hide()
        this.$refs.RequeteUpdatedModal.show()
        this.getAllRequetes()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteList modifyRequete API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRequeteInProcess = false
      })
    },


    // ----------- DELETE REQUETE -----
    showDeleteRequeteModal (requete) {
      this.deleteRequeteId = requete.id
      this.$refs.deleteRequeteModal.show()
    },

    deleteRequete () {
      this.deleteRequeteInProcess = true
      apiRequete.deleteRequete(this.token, this.deleteRequeteId)
      .then(() => {
        this.$refs.deleteRequeteModal.hide()
        this.$refs.RequeteDeletedModal.show()
        this.getAllRequetes()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteList deleteRequete API Error : ' + String(error))
      })
      .finally(() => {
        this.deleteRequeteInProcess = false
      })

    },


    // ----------- TEST REQUETE -----
    showTestRequeteModal (requeteId) {
      this.testRequeteNbKeywordsDetected = 0
      this.testRequeteHasBeenDone = false
      this.testRequeteText = ''
      this.testRequeteId = requeteId
      this.$refs.testRequeteModal.show()
    },

    testRequete () {
      this.testRequeteInProcess = true
      apiRequete.testRequete(this.token, this.testRequeteId, this.testRequeteText)
      .then((result) => {
        this.testRequeteNbKeywordsDetected = result.data['nb_keywords']
        this.testRequeteHighlights = result.data['highlights']
        this.testRequeteHasBeenDone = true
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteList testRequete API Error : ' + String(error))
      })
      .finally(() => {
        this.testRequeteInProcess = false
      })

    },
  }
}
</script>
